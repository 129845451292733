<template>
  <v-card class="s-card" v-bind="$attrs"><slot /></v-card>
</template>

<script>
export default {
  name: 'SCard',
  inheritAttrs: false,
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.s-card {
  padding: 2.5rem;
  border-radius: $default-radius !important;

  @include shadow-card;
}
</style>
